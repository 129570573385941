<template>
    <v-dialog v-model="isCaptureShow" max-width="800">
      <v-card v-show="width > 960">
        <v-card-title>
          對準鏡頭後，按下拍照。
          <v-btn color="#FC9D56" @click="selectCamera" dark>
            <v-icon>mdi-camera-retake</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <video id="video" ref="video" autoplay style="width: 375px;height: 250px" class="mirrorRotateLevel"></video>
            </v-col>
            <v-col cols="6">
              <div style="width: 375px;height: 250px">
                <canvas id="canvas" width="375" height="250" ref="canvas" class="mirrorRotateLevel"></canvas>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <!--<v-btn class="btn btn-primary" @click="onJoin">Join</v-btn>-->
          <div style="margin:0px auto;">
            <v-btn color="primary" class="ma-3" width="150" style="font-size: 18px;" @click="onCapture" dark>
              拍照
            </v-btn>
            <v-btn color="#FC9D56" class="ma-3" width="150" style="font-size: 18px;" @click="submitDialog" :disabled="!saveStatus" :dark="saveStatus">
              儲存{{uploadText}}
            </v-btn>
            <v-btn color="#559897" class="ma-3" width="150" style="font-size: 18px;" @click="closeDialog" dark>
              取消
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
      <v-card class="mx-auto py-0" v-if="false">
        <v-card-title>
          點選下方輸入框，點選拍照
        </v-card-title>
        <v-card-text class="mx-auto py-0">
          <v-row>
            <v-col cols="12" class="mx-auto py-0 mb-n5">
              <v-file-input
                v-model="uploadfile"
                label="請上傳學員大頭照"
                prepend-icon="mdi-camera-retake"
                outlined
                :clearable="false"
                :show-size="1000"
                :loading="isFileUploading"
                >
                <template v-slot:selection="{ file }">
                    <v-chip
                      :color="file.color"
                      dark
                      label
                      close
                      @click:close="uploadfile = null;previewImg=''"
                      style="position:absolute;z-index:99999;top:10px"
                    >
                    {{ file.name }}
                    </v-chip>
                    <v-img v-if="previewImg" :src="previewImg" max-height="150" class="my-2" position="left center" aspect-ratio="1.7" contain></v-img>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col class="py-0">
              <v-btn color="#FC9D56" class="ma-3" width="140" style="font-size: 18px;" @click="submitDialog" :disabled="!saveFileStatus" :dark="saveFileStatus">
                儲存
              </v-btn>
              <v-btn color="#559897" class="ma-3" width="140" style="font-size: 18px;" @click="closeDialog" dark>
                取消
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import {fileUpload} from "@/api/hyirecoApi";
import commonMixin from '@/utils/commonMixin';

export default {
  name: "captureDialog",
  mixins: [commonMixin],
  props: ['showCapture', 'captureData'],
  data: () => ({
    isCaptureShow: false,
    img: null,
    imgBase64: null,
    returnCaptureData: {
        memberId:"",
        fileId: "",
    },
    video: null,
    canvas: null,
    context: null,
    backCamera: true,
    uploadText: "",
    saveStatus: false,
    saveFileStatus: false,
    width: 1280,
    isFileUploading: false,
    uploadfile: null,
    previewImg: "",
  }),
  created() {
    //console.log("captureDialog created");
    this.isCaptureShow = this.showCapture;
    //console.log(window.innerWidth);
  },
  mounted() {
    //console.log("mounted");
    //this.video = this.$refs.video;
    //this.canvas = this.$refs.canvas;
    //this.context = this.canvas.getContext("2d");

    //this.startVideo(this.backCamera);
    //this.width = window.innerWidth;
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    init: function() {
      this.video = this.$refs.video;
      this.canvas = this.$refs.canvas;
      this.context = this.canvas.getContext("2d");
      this.startVideo(this.backCamera);
    },
    onResize: function(){
        //console.log(this.$vuetify.breakpoint.width);
        //console.log(window.innerWidth);
        this.width = window.innerWidth;
        //console.log(this.width);
    },
    startVideo: function(camera) {
      console.log(camera);
      var vedioJson = {
        video:{width:375, height:250}
      };

      if (camera) { 
          vedioJson = {
            video:{width:375, height:250, facingMode:{exact:'environment'}}
          };  
      }

      if (navigator.mediaDevices.getUserMedia || navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia){
          this.getUserMedia(vedioJson, this.success, this.error);
      } else {
          alert("你的瀏覽器不支援訪問使用者媒體裝置");
      }
    },
    getUserMedia: function(constrains,success,error){
      if(navigator.mediaDevices.getUserMedia){
          //最新標準API
          navigator.mediaDevices.getUserMedia(constrains).then(success).catch(error);
      } else if (navigator.webkitGetUserMedia){
          //webkit核心瀏覽器
          navigator.webkitGetUserMedia(constrains).then(success).catch(error);
      } else if (navigator.mozGetUserMedia){
          //Firefox瀏覽器
          navigator.mozGetUserMedia(constrains).then(success).catch(error);
      } else if (navigator.getUserMedia){
          //舊版API
          navigator.getUserMedia(constrains).then(success).catch(error);
      }
    },
    success: function(stream){
        //var CompatibleURL = window.URL || window.webkitURL;
        //this.video.src = CompatibleURL.createObjectURL(stream);
        window.stream = stream; // only to make stream available to console
        this.video.srcObject = stream;
        this.video.play();
    },
    error: function (error){
      alert("訪問媒體裝置失敗");
      console.log("訪問媒體裝置失敗：",error.name,error.message);
      if (this.backCamera) {
        this.backCamera = false;
        this.startVideo(this.backCamera);
      }
    },
    submitDialog: function() {
      var vm = this;
      vm.returnCaptureData = vm.captureData;

      if (this.width > 960) {
        this.saveStatus = true;
        var dataURL = this.canvas.toDataURL('image/jpeg');
        
        // var data = {
        //   fileBase64List: [],
        // };

        // data.fileBase64List.push(dataURL);
        //console.log("data = " + JSON.stringify(data));

        const auth = {
            headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + vm.getTokenWithSession(),
            },
            timeout: 180000,
        };
        
        var blob = vm.dataURLtoBlob(dataURL);
        //console.log("blob = " + blob);
        var file = new File([blob], "snapshot.jpg", {type: "'image/jpeg'",});
        let formData = new FormData();
        formData.append("files", file, file.name);

        fileUpload(formData, auth).then(function(response) {
          if ("200" == response.status) { 
              var responseData = response.data;
              //console.log("responseData = " + JSON.stringify(responseData));
              vm.saveStatus = false;
              if (responseData.statusCode === "1") {
                var data = responseData.fileMetaList;
                vm.returnCaptureData.fileId = data[0].fileKey;
                vm.$emit('captureEvent', vm.returnCaptureData);
              }
          }
        });
      } else {
        if (vm.uploadfile.fileId !== "") {
          //console.log(vm.uploadfile.fileId);
          vm.returnCaptureData.fileId = vm.uploadfile.fileId;
          vm.$emit('captureEvent', vm.returnCaptureData);
        }
      }
      

      //this.$emit('captureEvent', true);
    },
    closeDialog: function() {
        this.$emit('captureEvent', null);
    },
    onCapture() {
      //console.log("capture click");
      this.context.drawImage(this.video,0,0,375,250);
      this.saveStatus = true;
    },
    selectCamera: function() {
      this.backCamera = !this.backCamera;
      //alert(this.backCamera);
      this.startVideo(this.backCamera);
    },
    imageLoader(event){
      //console.log(event.target.result);
      this.previewImg=event.target.result;
    },
  },
  watch: {
    showCapture: function(value){
      //console.log("showCapture value = " + value);
      this.saveStatus = false;
      this.saveFileStatus = false;
      this.uploadfile = null;
      if (this.width > 960) {
        if (value) {
          setTimeout(() => this.init(), 1000);
        } else {
          //console.log("showCapture false");
          this.video.pause();
          var stream = this.video.srcObject;
          var tracks = stream.getTracks();

          for (var i = 0; i < tracks.length; i++) {
              var track = tracks[i];
              track.stop();
          }
          //video.srcObject = null;
        }
      }
      
      this.isCaptureShow = value;
    },
    isCaptureShow: function(value) { //按到灰色區域關閉conform，就回覆取消的event
      //console.log("isShow value = " + value);
      if (value === false){
        if (this.context !== null) {
          this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
          this.uploadText = "";
        } else {
          this.uploadfile = null;
          this.previewImg = '';
        }
        //console.log("this.img = " + this.img);
        this.$emit('captureEvent', null);
      }
    },
    
    uploadfile: function(value) {
      let vm = this;
      if (value !== null) {
        var file = value;
        file.color = "#FFA726";

        const reader = new FileReader(); //建立FileReader 監聽 Load 事件
        reader.addEventListener('load',vm.imageLoader);

        let formData = new FormData();
        formData.append("files", file, file.name);

        const auth = {
          headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getTokenWithSession(),
          },
          onUploadProgress: progressEvent => { 
              var percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              if (percent < 100) {
                  vm.isFileUploading = true;
              } else {
                  vm.isFileUploading = false;
              }
          },
        };

        fileUpload(formData, auth).then(function(response) {
          if ("200" == response.status) { 
              var responseData = response.data;
              //console.log(responseData);
              vm.saveFileStatus = true;
              if (responseData.statusCode === "1") {
                  var data = responseData.fileMetaList;
                  file.fileId = data[0].fileKey;
                  file.color = "deep-purple accent-4";
                  reader.readAsDataURL(file);
                  //console.log(vm.uploadfile);
              }
          }
        });

      }
    },
    
  }
}
</script>

<style scoped>
  .mirrorRotateLevel {          /* 水平镜像翻转 */
      -moz-transform:scaleX(-1);
      -webkit-transform:scaleX(-1);
      -o-transform:scaleX(-1);
      transform:scaleX(-1);
      /*兼容IE*/
      filter:FlipH;
  }
</style>