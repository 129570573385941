<template>
  <v-app>
    <v-container fluid>
        <body class="application application-offset">
            <div class="container-fluid container-application">
                <mainMenu /> <!-- ==== 左選單 ==== -->

                <div class="main-content position-relative">
                    <mainNav /> <!-- ==== Top選單 ==== -->

                    <div class="page-content index">
        
                        <!-- Page title -->
                        <div class="page-title">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-md-6 mb-3 mb-md-0" style="z-index:5">
                                    <h5 class="h3 font-weight-400 mb-0 text-white">嗨，{{userName}}!</h5>
                                    <span class="text-sm text-white opacity-8">祝你有個美好的一天!</span>
                                </div>
                            </div>
                        </div>

                        <div class="card" style="z-index: 2;">
                          <div class="card-header actions-toolbar border-0">
                            <div class="row justify-content-between align-items-center">
                                <div class="col" style="padding-top: 0px;padding-bottom: 0px;">
                                    <h2 class="d-inline-block mb-0 card-title">場域維護</h2>
                                </div>
                            </div>
                          </div>
                          <br/>
                            <!-- ==========主要內容區========== -->
                            <v-form ref="form" class="px-12" v-model="isValid">
                              <v-row>
                                <v-subheader>
                                  場域設定及管理
                                </v-subheader>
                              </v-row>     
                              <v-row>
                                <v-divider class="my-0"></v-divider>
                              </v-row>   
                              <v-row>
                                <v-col>
                                  <v-select
                                    :items="orgList"
                                    v-model="mainData.orgId"
                                    label="事業單位名稱"
                                    v-if="loginAuth.role === 'S'"
                                    @input="getSubUnitList"
                                    :clearable = !orgReadonly
                                    :rules="[rules.required]" required
                                    :readonly="orgReadonly"
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col sm="2" style="margin-bottom: 0px;">
                                  <v-switch
                                    v-model="mainData.status"
                                    label="啟用"
                                    color="success"
                                    hide-details
                                  ></v-switch>
                                </v-col>
                                <v-col>
                                  <v-text-field label="場域(活動)名稱" outlined v-model="mainData.jsName"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-menu
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="dateRangeStr"
                                        label="開始結束日期"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="dateRange"
                                      range
                                    ></v-date-picker>
                                  </v-menu>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col sm="4">
                                  <v-menu
                                    ref="clockMenu1"
                                    v-model="clockMenu1"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="mainData.statisticStartTime"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="mainData.statisticStartTime"
                                        label="每日統計時段起"
                                        prepend-icon="mdi-clock-time-four-outline"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-time-picker
                                      v-if="clockMenu1"
                                      v-model="mainData.statisticStartTime"
                                      format="24hr"
                                      full-width
                                      @click:minute="$refs.clockMenu1.save(mainData.statisticStartTime)"
                                    ></v-time-picker>
                                  </v-menu>
                                </v-col>
                                <v-col sm="4">
                                  <v-menu
                                    ref="clockMenu2"
                                    v-model="clockMenu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="mainData.statisticEndTime"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="mainData.statisticEndTime"
                                        label="每日統計時段迄"
                                        prepend-icon="mdi-clock-time-four-outline"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-time-picker
                                      v-if="clockMenu2"
                                      v-model="mainData.statisticEndTime"
                                      format="24hr"
                                      full-width
                                      @click:minute="$refs.clockMenu2.save(mainData.statisticEndTime)"
                                    ></v-time-picker>
                                  </v-menu>
                                </v-col>
                                <v-col sm="4">
                                   <v-text-field
                                        v-model="mainData.breakTime"
                                        label="休息時數"
                                        suffix="小時"
                                    ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-subheader>
                                    出入口
                                  </v-subheader>
                                </v-col>
                                <v-col sm="1">
                                  <v-btn
                                    class="ma-2"
                                    text
                                    icon
                                    color="#37906C"
                                    @click="addinoutListData"
                                  >
                                     <v-icon large class="mt-1">mdi-plus-box</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>        
                              <v-row>
                                <v-divider class="my-0"></v-divider>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-data-table
                                  :headers="headers"
                                  :items="inoutList"
                                  hide-default-footer
                                  hide-default-header
                                  disable-sort>
                                    <template v-slot:[`item.name`]={item}>
                                      <v-text-field v-model="item.name" label="出入口" outlined hide-details dense></v-text-field>
                                    </template>
                                    <template v-slot:[`item.act`]={item}>
                                      <v-btn class="mr-4 white--text" color="#4D4D4D" v-if="!item.editStatus" @click="deleteUnitData(item)">
                                        <v-icon>mdi-delete-forever</v-icon>
                                      </v-btn>
                                    </template>
                                  </v-data-table>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-subheader>
                                  子單位
                                </v-subheader>
                              </v-row>        
                              <v-row>
                                <v-divider class="my-0"></v-divider>
                              </v-row>                      
                              <v-row>
                                <v-col>
                                    <ul>
                                        <li v-for="(item, index) in subUnitList" :key="index" style="width: 33%;float:left;list-style-type:none;">
                                            <v-checkbox
                                                v-model="subUnitRelationshipList"
                                                :label="item.suName"
                                                :value="item"
                                                :readonly = "!isCreater"
                                            ></v-checkbox>
                                        </li>
                                    </ul>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-subheader>
                                  場域屬性
                                </v-subheader>
                              </v-row>        
                              <v-row>
                                <v-divider class="my-0"></v-divider>
                              </v-row>
                              <v-row>
                                <v-checkbox
                                    v-model="activity"
                                    label="「活動報到」：若勾選，該場域人員進出自動辨識出現報到按鈕。"
                                ></v-checkbox>
                                <v-checkbox
                                    v-model="notMemberPrompt"
                                    label="「非場域(活動)人員提示」：若勾選，該場域人員進出自動辨識判斷非人員選取清單則提示訊息。"
                                ></v-checkbox>
                              </v-row>
                              <v-row>
                                <v-divider class="my-0"></v-divider>
                                <br/>
                              </v-row>
                              <v-row justify="center">
                                    <v-btn class="mr-4 white--text" min-width="100" color="#37906C" large @click="submitData" :disabled="saveStatus || !isValid">
                                      儲存
                                    </v-btn>
                                    <v-btn class="mr-4 white--text" color="#4D4D4D" large @click="toCancel">
                                      回上一頁
                                    </v-btn>
                              </v-row>
                            </v-form>
                            <!-- ==========主要內容區========== -->
                            <br/>
                        </div>
                    </div>

                    <mainFooter /><!-- Footer -->
                    
                </div>
            </div>
        </body>
        <loadingMask :showLoading="isLoading" style="z-index:9999"></loadingMask>
        <captureDialog :showCapture="isCaptureDialog" :captureData="captureData"  @captureEvent="setCaptureData($event)" />
    </v-container>
  </v-app>
</template>

<script>
import mainMenu from '@/components/mainMenu.vue'
import mainNav from '@/components/mainNav.vue'
import mainFooter from '@/components/mainFooter.vue'
import commonMixin from '@/utils/commonMixin'
import loadingMask from '@/components/public/loadingMask.vue'
import captureDialog from '@/components/public/captureDialog.vue'
import {getJobSiteData, saveJobSite, addJobSite,
getOrgnizationNameSelectOption, getOrgnizationData} from "@/api/hyirecoApi"

export default {
  name: 'jobSiteEdit',
  mixins: [commonMixin],
  created(){
        this.authValidate();
        this.auth = {
          headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.getTokenWithSession(),
          }
        };
  },
  destroyed() {
    clearInterval(this.interval);
  },
  computed: {
    headers() {
      return [
          {
            text: '名稱',
            align: 'start',
            value: 'name',
            class: "subtitle-2 font-weight-black",
            width: "500",
          },
          { text: '動作', value: 'act', class: "subtitle-2 font-weight-black", align: 'start',width:"100"},
      ]
    },
  },
  mounted(){
    console.log("this.loginAuth = " + JSON.stringify(this.loginAuth));
    this.userName = this.loginAuth.memberName;
    if (this.loginAuth.role === 'S') {
      this.getOrgList();
    } else {
      this.mainData.orgId = this.loginAuth.orgnization.orgId;
      if (this.loginAuth.role === "O") {
        this.subUnitList = this.loginAuth.orgnization.subUnitList;
      } else {
        this.subUnitList = this.loginAuth.subUnitRelationshipList.map(function(item) {
          return item.subUnit;
        });
      }
    }

    if (this.$route.params.jsId === "init") { 
      this.orgReadonly = false;
      this.mainData.status = true;
    } else {
      this.getData();
    } 
  },
  methods: {
    getOrgList: function(){
      var vm = this;

      getOrgnizationNameSelectOption(vm.auth).then(function(response) {
        if ("200" == response.status) { 
          var responseData = response.data;
          console.log("getOrgnizationNameSelectOption = " + JSON.stringify(responseData));
          vm.orgList = responseData.orgnizationNameSelectOption.map(function(item) {
            var obj = new Object();
            obj.text = item.optionText;
            obj.value = item.optionValue;
            return obj;
          });
          console.log("orgList = " + JSON.stringify(vm.orgList));
        }
      });
    },
    getData: function(){
      this.isLoading = true;
      var vm = this;
      getJobSiteData(vm.$route.params.jsId, vm.auth).then(function(response) {
          if ("200" == response.status) { 
              var responseData = response.data;
              console.log("getJobSiteData responseData = " + JSON.stringify(responseData));

              vm.mainData = responseData.jobSite;
              vm.orgnizationName = vm.mainData.orgnization.name;
              vm.mainData.status = vm.mainData.status === "Y" ? true : false;
              vm.dateRange = [];
              vm.dateRange.push(vm.mainData.startDate.replace(/\//gi,"-"));
              vm.dateRange.push(vm.mainData.endDate.replace(/\//gi,"-"));
              //出入口
              vm.inoutList = vm.mainData.inoutList.map(function(item){
                var objUnit = new Object();
                objUnit.name = item;
                return objUnit;
              }); 

              //子單位
              if (vm.loginAuth.role === "J") {
                //如果是場域管理人員的話只能修改自己建立資料的子單位
                vm.isCreater = vm.loginAuth.userID == vm.mainData.createName;
              } else {
                //如果非場域管理人員，則讀出所有子單位
                vm.subUnitList = vm.mainData.orgnization.subUnitList
              }

              //vm.subUnitList = vm.mainData.orgnization.subUnitList;
              //vm.getSubUnitList();
              vm.subUnitRelationshipList = vm.mainData.subUnitRelationshipList.map(function(item) {
                  return item.subUnit;
              });
              //console.log("getOrgnizationMemberData subUnitRelationshipList = " + JSON.stringify(vm.subUnitRelationshipList));
              //console.log("getOrgnizationMemberData inoutList = " + JSON.stringify(vm.inoutList));

              vm.activity = vm.mainData.activity === "Y" ? true : false;
              vm.notMemberPrompt = vm.mainData.notMemberPrompt === "Y" ? true : false;
          }
      });
      this.isLoading = false;
    },
    submitData: function() {
      var vm = this;
      vm.isLoading = true;
      vm.saveStatus = true;

      var apiObj = saveJobSite;
      if (this.$route.params.jsId === "init") { 
        apiObj = addJobSite;
      }

      vm.mainData.subUnitRelationshipList = vm.subUnitRelationshipList;
      vm.mainData.inoutList = vm.inoutList.map(function (item) { return item.name;});
      vm.mainData.activity = vm.activity ? "Y" : "N";
      vm.mainData.notMemberPrompt = vm.notMemberPrompt ? "Y" : "N";
      vm.mainData.status = vm.mainData.status ? "Y" : "N";

      var dataObj = new Object();
      dataObj.jobSite = vm.mainData;
    
      console.log("dataObj = " + JSON.stringify(dataObj));
      apiObj(dataObj, vm.auth).then(function(response) {
          if ("200" == response.status) { 
            var responseData = response.data;
            vm.$swal(responseData.message);
            if (responseData.statusCode === "1") {
              vm.toCancel();
            }
            console.log("responseData = " + JSON.stringify(response));
            vm.saveStatus = false;
            vm.isLoading = false;
          }
      });
    },
    toCancel: function () {
      this.$router.push({ path: "/jobSite/jobSiteList" });
    },
    addinoutListData: function(){
      var vm = this;
      var objUnit = new Object();
      objUnit.name = "";
      vm.inoutList.push(objUnit);
    },
    deleteUnitData: function(obj) {
      this.inoutList.splice(this.inoutList.indexOf(obj),1);
    },
    getSubUnitList: function() {
      var vm = this;
      vm.subUnitRelationshipList = [];
      vm.subUnitList = [];
      if (this.loginAuth.role === "S") {
        if (vm.mainData.orgId !== null) {
          //console.log("vm.mainData.orgId = " + JSON.stringify(vm.mainData.orgId));
          getOrgnizationData(vm.mainData.orgId, vm.auth).then(function(response) {
            if ("200" == response.status) { 
              var responseData = response.data;
              //console.log("getSubUnitList = " + JSON.stringify(responseData));
              vm.subUnitList = responseData.orgnization.subUnitList;
            }
          });
        }
      } else {
        vm.subUnitList = this.loginAuth.subUnitRelationshipList.map(function(item) {
          var obj = new Object();
          obj.text = item.suName;
          obj.value = item.suName;
          return obj;
        });
      }

    },
  
  },
  watch: {
    dateRange: function(value){
      console.log(value);
      if (value.length === 0) this.dateRangeStr = "";
      if (value.length === 1) {
        this.mainData.startDate = value[0].replace(/-/gi,"/");
        this.mainData.endDate = value[0].replace(/-/gi,"/");
      } else {
        this.mainData.startDate = value[0].replace(/-/gi,"/");
        this.mainData.endDate = value[1].replace(/-/gi,"/");
      }
      this.dateRangeStr = this.mainData.startDate + "~" + this.mainData.endDate;
    }
  },
  data: () => ({
    rules: {
      required: value => !!value || '必填欄位',
      requiredForLabor: value => !!value || '若沒有請填 無',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || '不符規格的 e-mail 信箱'
      },
    },
    userName: "",
    orgnizationName: "",
    maxHeight: 250,
    mainData: {},
    isLoading: false,
    auth: {},
    saveStatus: false,
    isValid: true,
    inoutList:[],
    subUnitList:[],
    subUnitRelationshipList:[],
    orgList:[],
    isCaptureDialog: false,
    orgReadonly: true,
    isCreater: true,
    dateRange:[],
    captureData: {
        memberId:"",
        fileId: "",
    },
    pictureData: null,
    menu2: false,
    dateRangeStr: "",
    clockMenu1: false,
    clockMenu2: false,
    activity: false,
    notMemberPrompt: false,
  }),
  components: {
    mainMenu,
    mainNav,
    mainFooter,
    loadingMask,
    captureDialog,
  },
}
</script>

<style scoped>
.v-input__slot > .v-label
{
    margin-bottom: 0px;
}
.v-subheader {
    color: rgb(34, 34, 34) !important;
    font-size: large;
}
</style>